import Head from 'next/head'
import { LoginTemplate } from '../components/templates/LoginTemplate'

const LoginPage = () => {
  return (
    <>
      <Head>
        <title>Login</title>
      </Head>

      <LoginTemplate />
    </>
  )
}

export default LoginPage
