import { Box } from '@mui/system'
import { FormProvider, useForm } from 'react-hook-form'
import { signIn } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import type { StaticRoute } from 'nextjs-routes'
import { LoginCard, LoginFormState } from '../molecules/LoginCard'

export const LoginTemplate = () => {
  const methods = useForm<LoginFormState>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const router = useRouter()

  const { handleSubmit, clearErrors, setError } = methods

  const onSubmit = handleSubmit(async ({ email, password }) => {
    clearErrors()

    const result = await signIn('credentials', {
      email,
      password,
      redirect: false,
    })

    if (result?.error) {
      setError('root', { message: result.error })
    } else {
      router.push('/dashboard')
    }
  })

  useEffect(() => {
    if (router.query.error) {
      const query = new URLSearchParams(router.query as Record<string, string>)

      query.delete('error')

      router.replace(
        `${router.pathname}?${query.toString()}` as any as StaticRoute<'/'>,
        undefined,
        {
          shallow: true,
        },
      )
    }
  }, [router])

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FormProvider {...methods}>
        <LoginCard onSubmit={onSubmit} />
      </FormProvider>
    </Box>
  )
}
