import {
  TextFieldProps,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import { forwardRef, useMemo, useState } from 'react'
import { mdiEye, mdiEyeOff } from '@mdi/js'
import { Icon } from '../atoms/Icon'

export const InputWithVisibility = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ type, ...rest }, ref) => {
    const [visible, setVisible] = useState(false)

    const toggleVisibility = () => {
      setVisible(!visible)
    }

    const icon = useMemo(() => (visible ? mdiEye : mdiEyeOff), [visible])

    return (
      <TextField
        {...rest}
        ref={ref}
        type={visible ? type : 'password'}
        InputProps={{
          ...rest.InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={toggleVisibility}>
                <Icon path={icon} size={1} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    )
  },
)
