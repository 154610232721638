import { Button, ButtonProps, CircularProgress } from '@mui/material'

type LoadingButtonProps = {
  isLoading?: boolean
} & ButtonProps

export const LoadingButton = ({
  onClick,
  isLoading,
  size = 'large',
  variant = 'contained',
  color = 'secondary',
  disabled = false,
  children,
  ...rest
}: LoadingButtonProps) => (
  <Button
    size={size}
    color={color}
    variant={variant}
    onClick={onClick}
    disabled={isLoading || disabled}
    startIcon={isLoading && <CircularProgress size={28} color="inherit" />}
    {...rest}
  >
    {children}
  </Button>
)
