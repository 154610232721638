import { Typography, Button, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { Controller, useFormContext } from 'react-hook-form'
import Link from 'next/link'
import { LoadingButton } from './LoadingButton'
import { InputWithVisibility } from './InputWithVisibility'
import { AuthCard } from './AuthCard'

type LoginCardProps = {
  onSubmit: () => void | Promise<void>
}

export type LoginFormState = {
  email: string
  password: string
}

export const LoginCard = ({ onSubmit }: LoginCardProps) => {
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext<LoginFormState>()

  return (
    <AuthCard variant="outlined">
      <form onSubmit={onSubmit}>
        <Typography
          color="primary"
          variant="h3"
          textAlign="center"
          fontWeight="bolder"
          gutterBottom
        >
          Acesso Gestão SaaS FCamara
        </Typography>

        <Stack spacing={3}>
          <Typography textAlign="center">
            Preencha os campos abaixo para continuar
          </Typography>

          <Stack spacing={2}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: 'E-mail é obrigatório',
              }}
              render={({ field }) => (
                <TextField
                  color="primary"
                  margin="dense"
                  fullWidth
                  label="E-mail"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  {...field}
                />
              )}
            />

            <Stack spacing={2} alignItems="flex-end">
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <InputWithVisibility
                    color="primary"
                    margin="dense"
                    fullWidth
                    label="Senha"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    {...field}
                  />
                )}
                rules={{
                  required: 'Senha é obrigatória',
                }}
              />

              <Button
                variant="text"
                LinkComponent={Link}
                href="/forgot-password"
              >
                Esqueci minha senha
              </Button>
            </Stack>
          </Stack>

          {errors?.root && (
            <Typography textAlign="center" color="error" fontWeight="bold">
              {errors.root.message}
            </Typography>
          )}

          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            name="buttonSubmit"
            isLoading={isSubmitting}
          >
            Entrar
          </LoadingButton>
        </Stack>
      </form>
    </AuthCard>
  )
}
